<template>
  <v-container class="rewards" fill-height fluid>
    <v-row v-if="preventRedeeming" align="center" justify="center">
      <v-col cols="auto">
        <div class="rewards-text-title">
          Steam Key redemption has been temporarily disabled as we fix a security exploit
        </div>
      </v-col>
    </v-row>
    <v-row v-else-if="premium" align="center" justify="center">
      <v-col cols="12" md="5" class="pa-6 ma-6 mt-0 text-container">
        <div class="rewards-text-title">Thank you for supporting Disrupt<span class="text-primary">+</span>!</div>
        <div class="rewards-text">- Unlock a new key every 30 days</div>
        <div class="rewards-text">- 10% chance of being valued at $9.99 or more</div>
      </v-col>
      <v-col cols="12" md="3" class="pa-6 ma-6 key-container">
        <v-btn color="primary" v-if="wrongPlan" class="reward-button" :height="64" :width="360" tile :loading="loading">
          <v-icon class="mr-2">
            mdi-gift
          </v-icon>
          for Monthly Patrons Only
        </v-btn>
        <v-btn color="primary" v-else-if="!key" class="reward-button" :height="64" :width="360" tile :loading="loading" @click="claimKey">
          <v-icon class="mr-2">
            mdi-lock-open
          </v-icon>
          Unlock Steam Key
        </v-btn>
        <div color="white" v-else class="key-text reward-button black--text" :height="64" :loading="loading" :width="360" tile>
          {{ key.code }}
        </div>
        <div class="next-key" v-if="nextKey">
          Next key in {{ nextKey }} days
        </div>
      </v-col>
      <v-col cols="1"></v-col>
    </v-row>
    <v-row v-else align="center" justify="center">
      <v-col cols="12" md="5" class="pa-6 ma-6 text-container">
        <div class="rewards-text-title">Earn rewards for supporting Disrupt<span class="text-primary">+</span>!</div>
        <div class="rewards-text">- Unlock a Steam<sup>TM</sup> game key every 30 days</div>
        <div class="rewards-text">- 10% chance of being $9.99 in value</div>
        <div class="rewards-text">- Exclusive for monthly Patrons</div>
      </v-col>
      <v-col cols="12" md="3" class="pa-6 ma-6 key-container">
        <v-btn color="primary" class="reward-button" :height="64" :width="360" tile :loading="loading" @click="joinButton">
          <v-icon class="mr-2">
            mdi-gift
          </v-icon>
          Join to claim your key
        </v-btn>
      </v-col>
      <v-col cols="1"></v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'

export default {
  name: 'Rewards',
  components: {
  },
  data: () => ({
    loading: true,
    canRedeem: false,
    preventRedeeming: false,
    key: '',
    wrongPlan: false
  }),
  computed: {
    moment() {
      return moment;
    },
    user () {
      return this.$store.getters.getUser;
    },
    activeSubscription () {
      if (this.user && this.user.subscriptions) {
        return this.user.subscriptions.find(s => s.active);
      }
      return null;
    },
    premium () {
      if (this.activeSubscription && this.activeSubscription.premium) {
        return true;
      }
      return false;
    },
    nextKey () {
      if (this.key) {
        let now = new Date();
        let lastRedeemed = new Date(this.key.redeemedDate);

        let days = Math.round((now-lastRedeemed)/(1000*60*60*24));
        return 30 - days;
      }
      return '';
    },
    isLoggedIn() {
      return this.$store.getters.getIsLoggedIn;
    }
  },
  methods: {
    canRedeemReward: function () {
      return this.$store.dispatch('canRedeemReward');
    },
    getReward: function () {
      let scope = this;
      this.$store.dispatch('getReward').then(function (res) {
        scope.key = res.data
      });
    },
    getLastReward: function () {
      let scope = this;
      return new Promise((resolve) => {
        scope.$store.dispatch('getLastReward').then(function (res) {
          scope.key = res.data
          resolve();
        });
      })
    },
    navigate: function (path) {
      this.$router.push(path).catch(()=>{});
    },
    joinButton: function () {
      if (this.user) {
        this.navigate('/upgrade');
      } else {
        this.navigate('/register');
      }
    },
    claimKey: function () {
      this.getReward();
    }
  },
  mounted () {
    let scope = this;

    scope.loading = true;

    if (scope.isLoggedIn) { 
      scope.canRedeemReward().then(function (res) {
        scope.canRedeem = res.data;

        if (scope.canRedeem == 1) {
          scope.loading = false;
        } else if (scope.canRedeem == 2) {
          scope.loading = false;
          scope.wrongPlan = true;
        } else if (scope.canRedeem == 3) {
          scope.loading = false;
          scope.preventRedeeming = true;
        } else {
          scope.getLastReward().then(function () {
            scope.loading = false;
          });
        }
      })
    } else {
      scope.loading = false;
    }
  }
}
</script>
<style lang="scss">
.rewards {
  background-image: radial-gradient(circle at 300% 50%, #00486f 40%, #0000ff00 80%)
}

.rewards-text-title {
  font-size: 32px;
  text-align: right;
}

.rewards-text {
  font-size: 24px;
  text-align: right;
  font-style: italic;
}

.reward-button {
  text-transform: none;
  font-size: 20px !important;
  font-style: italic;
  margin: auto;
  display: block;
}

.text-primary {
  color: var(--v-primary-base) !important;
}
.key-text {
  padding: 16px 0px;
  text-align: center;
  width: 360px;
  background: white;
  letter-spacing: 0.0892857143em;
}

.key-container {
  position: relative;
}

.next-key {
  position: absolute;
  top: 102px;
  text-align: center;
  width: 50%;
  left: 25%;
}

// mobile
.disrupt-mobile .rewards {
  background-image: linear-gradient(to top, #00486faa 0%, #000000aa 90%)
}
.disrupt-mobile .rewards-text-title {
  text-align: center;
  padding-bottom: 64px;
}
.disrupt-mobile .rewards-text {
  text-align: center;
  font-size: 20px;
}
.disrupt-mobile .reward-button {
  max-width: 80vw;
}
.disrupt-mobile .text-container {
  max-height: 44vh;
}
.disrupt-mobile .key-container {
  max-height: 44vh;
}
</style>
